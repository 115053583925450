import React from 'react'
import classnames from 'classnames'

import { useSliderContext } from '../../hooks/useSliderContext'

interface Props {
  className?: string
}

export const Content: React.FC<Props> = ({ className }) => {
  const { content } = useSliderContext()

  return (
    <p
      className={classnames('Slider-content', className)}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
