import { isSendiumAxiosError } from './isSendiumAxiosError'
import { SendiumAxiosError } from '../types'

export const DEFAULT_ERROR_MESSAGE = 'Error: Something went wrong'

export const getMessageFromError = (
  error: Error | SendiumAxiosError,
): string => {
  if (isSendiumAxiosError(error)) {
    return error.response ? error.response.data.error : DEFAULT_ERROR_MESSAGE
  } else {
    return error.message || DEFAULT_ERROR_MESSAGE
  }
}
