import * as React from 'react'

import { ModalContainer } from '../../components/ModalContainer'
import * as Modal from '../../components/Modal'
import constants from './constants'
import { ModalName } from '../../types'
import './PrivacyPolicyModal.scss'

interface Props {
  closeModal: () => void
}

export const PrivacyPolicyModalContent: React.FC<Props> = ({ closeModal }) => (
  <Modal.Wrapper>
    <Modal.Header title={constants.MODAL_TITLE} onClose={closeModal} />
    <Modal.Content className="PrivacyPolicy-content">
      {constants.SECTIONS.map(({ title, content }) => (
        <div className="PrivacyPolicy-section" key={title}>
          {title && <p className="PrivacyPolicy-sectionTitle">{title}</p>}
          <p>{content}</p>
        </div>
      ))}
    </Modal.Content>
    <Modal.Footer>
      <button className="PrivacyPolicy-footerButton" onClick={closeModal}>
        Close
      </button>
    </Modal.Footer>
  </Modal.Wrapper>
)

const PrivacyPolicyModal: React.FC = () => (
  <ModalContainer modalName={ModalName.PRIVACY_POLICY}>
    {(closeModal) => <PrivacyPolicyModalContent closeModal={closeModal} />}
  </ModalContainer>
)

export default PrivacyPolicyModal
