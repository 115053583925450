export enum Environments {
  'DEVELOPMENT' = 'development',
  'PRODUCTION' = 'production',
  'TEST' = 'test',
}

export enum PaymentProcessors {
  ADYEN = 'ADYEN',
  AUTHNET = 'AUTHNET',
}

export const isProduction = (env: string = import.meta.env.MODE): boolean =>
  env === Environments.PRODUCTION

export const paymentProcessors = {
  isAuthNet: (
    paymentProcessor: string = import.meta.env.VITE_PAYMENT_PROCESSOR || '',
  ): boolean => paymentProcessor === PaymentProcessors.AUTHNET,
}
