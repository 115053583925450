import React from 'react'
import classnames from 'classnames'

import './Footer.scss'

export interface Props {
  className?: string
  children?: React.ReactNode
}

export const Footer: React.FC<Props> = ({ children, className }) => (
  <div className={classnames('Modal-footer', className)}>{children}</div>
)
