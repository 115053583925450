import React from 'react'
import classnames from 'classnames'

import './Header.scss'

export interface Props {
  className?: string
  onClose?: () => void
  reauthorizationRequired?: boolean
  title?: string
  children?: React.ReactNode
}
export const Header: React.FC<Props> = ({
  children,
  className,
  onClose,
  reauthorizationRequired = false,
  title,
}) => (
  <>
    <div className={classnames('ModalHeader-wrapper', className)}>
      <h1 className="ModalHeader-title">{title}</h1>
      <div>
        {children}

        {!reauthorizationRequired && (
          <button
            aria-label="close modal"
            className="ModalHeader-closeModal"
            onClick={onClose}
          />
        )}
      </div>
    </div>
    <div className="ModalHeader-bottomBorder" />
  </>
)
