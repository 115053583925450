import React from 'react'

import { RouteMap } from '../RouteMap'

interface Props {
  preloadPaths: string[]
}

const PreloadLazyComponents: React.FC<Props> = ({ preloadPaths }) => {
  React.useEffect(() => {
    preloadPaths.forEach((path) => {
      const route = RouteMap[path]

      if (route) {
        route.Component.preload()
      }
    })
  }, [preloadPaths])

  return null
}

export default React.memo(PreloadLazyComponents)
