import { endpoints } from '../endpoints'
import { GetSettingsResponse } from '../../types'
import * as http from '../http'

export interface SettingsApi {
  load: () => GetSettingsResponse
}

export const settings: SettingsApi = {
  load: () => http.unauthenticated.get(endpoints.settings, { action: 'get' }),
}
