import React from 'react'
import classnames from 'classnames'

import './Banner.scss'

export type Props = {
  onClose: () => void
  message: string
  inModal: boolean
  isModalOpen: boolean
}

export const Banner: React.FC<Props> = ({
  onClose,
  message,
  inModal,
  isModalOpen,
}) => {
  const visible = !!message && (inModal ? isModalOpen : !isModalOpen)
  const bannerClasses = classnames('Banner', {
    'Banner--inModal': inModal,
    'Banner--visible': visible,
  })

  return (
    <div aria-hidden={!visible} className={bannerClasses}>
      <span aria-label="error message" className="Banner-errorMessage">
        {message}
      </span>

      <button
        aria-label="dismiss error"
        className="Banner-closeButton"
        onClick={onClose}
      >
        X
      </button>
    </div>
  )
}
