import React from 'react'

import { Slider } from '../Slider'
import './ValuePropSlider.scss'

export const ValuePropSlider: React.FC = () => {
  return (
    <Slider.Container className="ValuePropSlider-container">
      <div className="ValuePropSlider-innerContainer">
        <div className="ValuePropSlider-forwardBtn">
          <Slider.ForwardButton />
        </div>

        <div className="ValuePropSlider-backwardBtn">
          <Slider.BackwardButton />
        </div>

        <Slider.Icon className="ValuePropSlider-icon" />

        <Slider.Title className="ValuePropSlider-title" />

        <Slider.Content className="ValuePropSlider-content" />
      </div>
    </Slider.Container>
  )
}
