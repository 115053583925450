export const getURL = (path: string) =>
  `${import.meta.env.VITE_PORTAL_BASE_URL}${path}`

export { addTransitionToChild } from './addTransitionToChild'
export { buildProductIdentifier } from './buildProductIdentifier'
export { closeModalAndNavigate } from './closeModalAndNavigate'
export { getMessageFromError } from './getMessageFromError'
export {
  isCallTagResponse,
  isLabelResponse,
  isPickupResponse,
  isTrackingResponse,
} from './identifyResponseProduct'
export { isSendiumAxiosError } from './isSendiumAxiosError'
export { logEventInDataLayer } from './logEventInDataLayer'
