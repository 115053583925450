import { endpoints } from '../endpoints'
import { GetAuthorizationResponse } from '../../types'
import * as http from '../http'

export interface AuthorizationApi {
  checkAuth: (token: string) => GetAuthorizationResponse
}

export const authorization: AuthorizationApi = {
  checkAuth: (token) =>
    http.unauthenticated.get(endpoints.user, { action: 'check_auth', token }),
}
