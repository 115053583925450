export const routes = {
  any: '*',
  about: '/about',
  agreements: '/agreements',
  callTag: '/call-tag',
  callTagInstructions: (uniqueID: string = ':uniqueID') =>
    `/call-tag-instructions/${uniqueID}`,
  carbonNeutral: '/carbon-neutral',
  functionFlags: '/betazed',
  home: '/',
  howManyPackages: '/how-many-packages',
  logisticsPartner: '/logistics-partner',
  orderConfirmation: (uniqueID: string = ':uniqueID') =>
    `/order-confirmation/${uniqueID}`,
  orderHelp: (uniqueID: string = ':uniqueID') =>
    `/order-confirmation/${uniqueID}/help`,
  orderSummary: '/order-summary',
  packageLocation: '/package-location',
  packageSummary: '/package-summary',
  packages: {
    labelDimensions: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/label-dimensions`,
    labelDeliveryAddress: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/label-delivery-address`,
    labelPickupAddress: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/label-pickup-address`,
    name: (packageNumber: ':packageNumber' | number = ':packageNumber') =>
      `/packages/${packageNumber}/name`,
    newLabel: (packageNumber: ':packageNumber' | number = ':packageNumber') =>
      `/packages/${packageNumber}/newLabel`,
    logisticsPartner: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/logistics-partner`,
    packageLocation: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/package-location`,
    pickupAddress: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/pickup-address`,
    pickupDate: (packageNumber: ':packageNumber' | number = ':packageNumber') =>
      `/packages/${packageNumber}/pickup-date`,
    reviewTrackingInfo: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/review-tracking-info`,
    schedulePickup: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/schedule-pickup`,
    specialInstructions: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/special-instructions`,
    trackingNumber: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/tracking-number`,
    whatKindOfLabel: (
      packageNumber: ':packageNumber' | number = ':packageNumber',
    ) => `/packages/${packageNumber}/what-kind-of-label`,
  },
  payment: '/payment',
  pickupAddress: '/pickup-address',
  printLabels: (uniqueID: string = ':uniqueID') =>
    `/print-your-labels/${uniqueID}`,
  selectService: '/select-service',
  specialInstructions: '/special-instructions',
}
