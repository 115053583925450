import { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit'
import { ModalName } from '../../../types'

import { actions as authorizationTimeoutActions } from '../../authorizationTimeout'
import { actions as modalActions } from '../../modal'

export const authorizationTimeout: Middleware<{}, any, Dispatch<AnyAction>> = (
  _store,
) => (next) => (action) => {
  if (
    action.type === authorizationTimeoutActions.setAuthorizationTimeout.type
  ) {
    if (action.payload) {
      next(modalActions.setOpenModalName(ModalName.AUTHORIZATION_TIMEOUT))
    } else {
      next(modalActions.setOpenModalName(ModalName.NONE))
    }
  }

  return next(action)
}
