import React from 'react'

import { ApiResult, SettingsApiData } from '../../types'

interface Props {
  loading: boolean
  settings: SettingsApiData
  children: React.ReactNode
}
export const DataGuard: React.FC<Props> = ({ children, loading, settings }) => {
  if (loading || settings.result !== ApiResult.SUCCESS) return null

  return <>{children}</>
}
