import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch } from '../../types'
import { Banner } from './Banner'
import {
  actions as errorActions,
  selectors as errorSelectors,
} from '../../redux/error'
import { selectors as modalSelectors } from '../../redux/modal'

type Props = {
  inModal?: boolean
}

const WrappedBanner: React.FC<Props> = (props) => {
  const dispatch = useDispatch<AppDispatch>()

  const errorMessage = useSelector(errorSelectors.getError)
  const onClose = () => dispatch(errorActions.clearError())
  const inModal = Boolean(props.inModal)
  const isModalOpen = Boolean(useSelector(modalSelectors.getOpenModalName))

  return (
    <Banner
      onClose={onClose}
      message={errorMessage}
      inModal={inModal}
      isModalOpen={isModalOpen}
    />
  )
}

export default WrappedBanner
