import { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit'

import { actions as errorActions } from '../../error'
import { actions as modalActions } from '../../modal'

export const errorBanner: Middleware<{}, any, Dispatch<AnyAction>> = (
  _store,
) => (next) => (action) => {
  if (action.type === modalActions.setOpenModalName.type) {
    next(errorActions.clearError())
  }

  return next(action)
}
