import { createRoot } from 'react-dom/client';

import App from './App'
import './index.scss'
import { AuthNetDispatchData } from './types.ts'

const container = document.getElementById('root')
const root = createRoot(container!)

declare global {
  var Accept: {
    dispatchData: AuthNetDispatchData
  }
  var dataLayer: object[]
}


root.render(<App />)
