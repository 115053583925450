import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'

import DataGuard from './components/DataGuard'
import { persistor, store } from './redux/store'
import { Router } from './Router'
import { ScrollContextProvider } from './contexts/scrollContext'

const TAB_KEY = 9
const ieRedirectUrl =
  'https://support.microsoft.com/en-us/topic/we-recommend-viewing-this-website-in-microsoft-edge-160fa918-d581-4932-9e4e-1075c4713595'

const App: React.FC = () => {
  React.useEffect(() => {
    const { userAgent } = window.navigator

    if (userAgent.indexOf('MSIE') > 0 || userAgent.indexOf('Trident') > 0) {
      window.location.replace(ieRedirectUrl)
    }

    document.body.addEventListener('mousedown', function () {
      document.body.classList.add('using-mouse')
    })

    document.body.addEventListener('keydown', function (event) {
      if (event.keyCode === TAB_KEY) {
        document.body.classList.remove('using-mouse')
      }
    })
  }, [])

  return (
    <Provider store={store}>
      <ScrollContextProvider>
        <PersistGate loading={true} persistor={persistor}>
          <DataGuard>
            <Router />
          </DataGuard>
        </PersistGate>
      </ScrollContextProvider>
    </Provider>
  )
}

export default App
