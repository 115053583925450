import { endpoints } from '../endpoints'
import * as http from '../http'
import {
  SendPhoneCodeForOrderResponse,
  SendPhoneCodeResponse,
  VerifyPhoneCodeResponse,
} from '../../types'

export interface PhoneApi {
  sendCode: (phoneNumber: string) => SendPhoneCodeResponse
  sendCodeForExistingOrder: (
    orderUniqueID: string,
  ) => SendPhoneCodeForOrderResponse
  verify: (phoneNumber: string, code: string) => VerifyPhoneCodeResponse
  verifyForExistingOrder: (
    orderUniqueID: string,
    code: string,
  ) => VerifyPhoneCodeResponse
}

export const phone: PhoneApi = {
  sendCode: (phoneNumber) =>
    http.unauthenticated.get(endpoints.phone, {
      action: 'send_code',
      phone: phoneNumber,
    }),
  sendCodeForExistingOrder: (orderUniqueID) =>
    http.unauthenticated.get(endpoints.phone, {
      action: 'send_code_for_id',
      unique_id: orderUniqueID,
    }),
  verify: (phoneNumber, code) =>
    http.unauthenticated.get(endpoints.phone, {
      action: 'verify_code',
      code,
      phone: phoneNumber,
    }),
  verifyForExistingOrder: (orderUniqueID, code) =>
    http.unauthenticated.get(endpoints.phone, {
      action: 'verify_code',
      code,
      unique_id: orderUniqueID,
    }),
}
