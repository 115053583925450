import { ApiPromiseResponse, VerifyTrackingNumberResponse } from '../../types'
import { endpoints } from '../endpoints'
import * as http from '../http'

export interface TrackingNumberApi {
  verify: (
    trackingNumber: string,
  ) => ApiPromiseResponse<VerifyTrackingNumberResponse>
}

export const trackingNumber = {
  verify: (trackingNumber: string) =>
    http.unauthenticated.get(endpoints.tracking, {
      action: 'verify',
      tracking_number: trackingNumber,
    }),
}
