import React from 'react'
import classnames from 'classnames'

import { useSliderContext } from '../../hooks/useSliderContext'

interface Props {
  className?: string
}

export const ForwardButton: React.FC<Props> = ({ className }) => {
  const { goForward } = useSliderContext()

  return (
    <button
      className={classnames('Slider-button Slider-button--forward', className)}
      onClick={goForward}
      type="button"
    >
      Next slide
    </button>
  )
}
