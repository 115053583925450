import { BackwardButton } from './BackwardButton'
import { Container } from './Container'
import { Content } from './Content'
import { ForwardButton } from './ForwardButton'
import { Icon } from './Icon'
import { Title } from './Title'
import './Slider.scss'

export const Slider = {
  BackwardButton,
  Container,
  Content,
  ForwardButton,
  Icon,
  Title,
}
