import qs from 'qs'
import { endpoints } from '../endpoints'
import * as http from '../http'
import { GetPricesRequestParams, GetPricesResponse } from '../../types'

export interface PriceApi {
  load: (params: GetPricesRequestParams) => GetPricesResponse
}

export const prices: PriceApi = {
  load: (params) =>
    http.unauthenticated.get(
      `${endpoints.product}?${qs.stringify(params)}`,
      {},
    ),
}
