import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Address, RootState, VerifedAddressState } from '../../types'

export const selectors = {
  getVerifiedAddresses: (state: RootState) => state.verifiedAddresses,
}

interface Payload {
  stringifiedAddress: string
  address: Address
}

export const initialState: VerifedAddressState = {}
export const { actions, reducer } = createSlice({
  name: 'verifiedAddresses',
  initialState,
  reducers: {
    add: (state: VerifedAddressState, action: PayloadAction<Payload>) => {
      if (!state[action.payload.stringifiedAddress]) {
        return {
          ...state,
          [action.payload.stringifiedAddress]: action.payload.address,
        }
      } else {
        return state
      }
    },
  },
})
