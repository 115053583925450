import React from 'react'
import { Action, Location } from 'history'
import { matchPath } from 'react-router-dom'

import {
  NavigationDirection,
  PageHeight,
  TransitionDirection,
  TransitionTypeMap,
} from '../types'
import { routes } from '../routes'

const TRANSITION_TYPES: TransitionTypeMap = {
  [PageHeight.SMALL]: {
    [PageHeight.SMALL]: {
      [NavigationDirection.FORWARD]: TransitionDirection.FWD,
      [NavigationDirection.BACK]: TransitionDirection.BACK,
    },
    [PageHeight.LARGE]: {
      [NavigationDirection.FORWARD]: TransitionDirection.FWD_TO_LARGE_PAGE,
      [NavigationDirection.BACK]: TransitionDirection.BACK_TO_LARGE_PAGE,
    },
  },
  [PageHeight.LARGE]: {
    [PageHeight.SMALL]: {
      [NavigationDirection.FORWARD]: TransitionDirection.FWD_FROM_LARGE_PAGE,
      [NavigationDirection.BACK]: TransitionDirection.BACK_FROM_LARGE_PAGE,
    },
    [PageHeight.LARGE]: {
      [NavigationDirection.FORWARD]: TransitionDirection.FWD_TO_LARGE_PAGE,
      [NavigationDirection.BACK]: TransitionDirection.BACK_TO_LARGE_PAGE,
    },
  },
}

export const getTransitionType = (
  prevPageHeight: PageHeight,
  nextPageHeight: PageHeight,
  navigationDirection: NavigationDirection,
) => TRANSITION_TYPES[prevPageHeight][nextPageHeight][navigationDirection]

const findPage = (element: React.ReactElement, path: string) => {
  const switchChild = element.props.children
  const renderedRoutes = switchChild.props.children

  return renderedRoutes.find((route: any) =>
    matchPath(path, {
      path: route.props && route.props.path,
      exact: true,
    }),
  )
}

export const handleLocationChanges = (
  prevLocation: React.MutableRefObject<Location>,
  nextLocation: Location,
  locations: React.MutableRefObject<Location[]>,
  historyAction: Action,
  navDirection: React.MutableRefObject<NavigationDirection>,
) => {
  if (nextLocation.key !== locations.current[0].key) {
    if (historyAction === 'PUSH') {
      navDirection.current = NavigationDirection.FORWARD
      prevLocation.current = locations.current[0]
      locations.current = [nextLocation]
    } else {
      if (nextLocation.pathname === locations.current[1]?.pathname) {
        navDirection.current = NavigationDirection.FORWARD
        const [previousLocation, ...remainingLocations] = locations.current
        prevLocation.current = previousLocation

        locations.current = remainingLocations
      } else {
        navDirection.current = NavigationDirection.BACK
        prevLocation.current = locations.current[0]
        locations.current = [nextLocation, ...locations.current]
      }
    }
  }
}

export const addTransitionToChild = (
  child: React.ReactElement,
  prevPath: string,
  nextPath: string,
  navDirection: NavigationDirection,
) => {
  if (nextPath === prevPath) return child

  const prevPage = findPage(child, prevPath)
  const nextPage = findPage(child, nextPath)

  const prevPageHeight = prevPage.props.pageHeight || PageHeight.SMALL
  const nextPageHeight = nextPage.props.pageHeight || PageHeight.SMALL

  const transition = getTransitionType(
    prevPageHeight,
    nextPageHeight,
    nextPath === routes.home ? NavigationDirection.BACK : navDirection,
  )

  return React.cloneElement(child, { classNames: transition })
}
