import { isValid, parse } from 'date-fns'

import { Pickup } from '../../types'

export const PICKUP_DATE_FORMAT = 'MM/dd/yyyy'

const pickupDateStatus = (pickup: Pickup): string => {
  const pickupDate = parse(
    pickup.pickup_date || '',
    PICKUP_DATE_FORMAT,
    new Date(),
  )

  if (isValid(pickupDate)) {
    return pickup.status
  }

  return ''
}

const isComplete = (pickup: Pickup): boolean => {
  const { location, pickup_date } = pickup

  return (
    pickup.product_id > 0 &&
    !!location.address.address_id &&
    !!location.contact.phone &&
    !!pickup_date
  )
}

export const PickupModel = {
  isComplete,
  pickupDateStatus,
}
