import { endpoints } from '../endpoints'
import * as http from '../http'
import {
  ApiPromiseResponse,
  ApiResult,
  CancelOrderRequestParams,
  ConfirmOrderResponse,
  CreateOrderRequestParams,
  CreateOrderResponse,
  EmailOrderRequestParams,
  LoadOrderResponse,
  RescheduleOrderRequestParams,
} from '../../types'

export interface OrderApi {
  cancel: (
    params: CancelOrderRequestParams,
  ) => ApiPromiseResponse<{ result: ApiResult }>
  confirm: (
    jobID: string,
    token: string,
    payment_identifier1: any,
  ) => ConfirmOrderResponse
  create: (params: CreateOrderRequestParams) => CreateOrderResponse
  email: (params: EmailOrderRequestParams) => ApiPromiseResponse<void>
  load: (uniqueID: string, token?: string) => LoadOrderResponse
  reportIssue: (
    jobID: string,
    message: string,
    token: string,
  ) => ApiPromiseResponse<void>
  reschedule: (
    params: RescheduleOrderRequestParams,
  ) => ApiPromiseResponse<{ result: ApiResult }>
  update: (
    params: CreateOrderRequestParams,
    job_id: string,
  ) => CreateOrderResponse
}

export const order: OrderApi = {
  cancel: (params) =>
    http.unauthenticated.post(endpoints.order, {
      action: 'cancel',
      ...params,
    }),
  confirm: (jobID, token, paymentMethod) =>
    http.unauthenticated.post(endpoints.order, {
      action: 'confirm',
      job_id: jobID,
      token,
      payment_identifier1: paymentMethod,
    }),
  create: (params) =>
    http.unauthenticated.post(endpoints.order, {
      action: 'add',
      ...params,
    }),
  email: (params) =>
    http.unauthenticated.post(endpoints.order, {
      ...params,
      action: 'email_details',
    }),
  load: (uniqueID, token) =>
    http.unauthenticated.get(endpoints.order, {
      action: 'get',
      unique_id: uniqueID,
      token,
    }),
  reportIssue: (jobID, message, token) =>
    http.unauthenticated.post(endpoints.user, {
      action: 'report_issue',
      job_id: jobID,
      message,
      token,
    }),
  reschedule: (params) =>
    http.unauthenticated.post(endpoints.order, {
      action: 'reschedule',
      ...params,
    }),
  update: (params, jobID: string) =>
    http.unauthenticated.post(endpoints.order, {
      action: 'update',
      job_id: jobID,
      ...params,
    }),
}
