import { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import Banner from '../../components/Banner'
import { buildValuePropSlides } from '../../utils/buildValuePropSlides'
import Loader from '../../components/Loader'
import { HomeLayout } from './HomeLayout'
import PrivacyPolicyModal from '../../modals/PrivacyPolicyModal'
import { RouteTransition } from '../../components/RouteTransition'
import { selectors } from '../../redux/settings'
import { SliderContextProvider } from '../../contexts/sliderContext'
import TermsAndConditionsModal from '../../modals/TermsAndConditionsModal'
import './HomeLayout.scss'

const HomeLayoutContainer = ({ children }: { children: ReactNode }) => {
  const marketingItems = useSelector(selectors.getMarketingsItems)
  const menuItems = useSelector(selectors.getMenuItems)
  const valuePropositions = useSelector(selectors.getValuePropositions)
  const i18nTextMap = useSelector(selectors.getText)
  const slides = buildValuePropSlides(valuePropositions, i18nTextMap)

  return (
    <>
      <SliderContextProvider slides={slides}>
        <HomeLayout marketingItems={marketingItems} menuItems={menuItems}>
          {children}
        </HomeLayout>
      </SliderContextProvider>

      <RouteTransition />

      <Banner />

      <Loader />

      <TermsAndConditionsModal />

      <PrivacyPolicyModal />
    </>
  )
}

export default HomeLayoutContainer
