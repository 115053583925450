import { Label } from '../../types'

const isComplete = (label: Label): boolean => {
  const { delivery_location, dimensions, name, pickup_location } = label

  return (
    !!name &&
    !!delivery_location.address.address_id &&
    !!dimensions.height &&
    !!dimensions.length &&
    !!dimensions.width &&
    !!pickup_location.address.address_id
  )
}

const lastCompletedLabel = (labels: Label[]) => {
  const completeLabels = labels.filter(isComplete)

  return completeLabels[completeLabels.length - 1]
}

export const LabelModel = {
  isComplete,
  lastCompletedLabel,
}
