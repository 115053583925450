import * as React from 'react'
import { decode } from 'html-entities'
import { useSelector } from 'react-redux'

import { locale } from '../../locale'
import { ModalContainer } from '../../components/ModalContainer'
import * as Modal from '../../components/Modal'
import { ModalName } from '../../types'
import { selectors as settingsSelectors } from '../../redux/settings'
import './TermsAndConditionsModal.scss'

interface Props {
  closeModal: () => void
  content: string
  title: string
}

export const TermsAndConditionsModalContent: React.FC<Props> = ({
  closeModal,
  content,
  title,
}) => (
  <Modal.Wrapper>
    <Modal.Header title={title} onClose={closeModal} />
    <Modal.Content>
      <span
        className="TermsAndConditions-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Modal.Content>
    <Modal.Footer>
      <button className="TermsAndConditions-footerButton" onClick={closeModal}>
        Close
      </button>
    </Modal.Footer>
  </Modal.Wrapper>
)

const TermsAndConditionsModal: React.FC = () => {
  const { terms_text, terms_title } = useSelector(settingsSelectors.getText)
  const content = decode(terms_text[locale])
  const title = decode(terms_title[locale])

  return (
    <ModalContainer modalName={ModalName.TERMS_AND_CONDITIONS}>
      {(closeModal) => (
        <TermsAndConditionsModalContent
          closeModal={closeModal}
          content={content}
          title={title}
        />
      )}
    </ModalContainer>
  )
}

export default TermsAndConditionsModal
