import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LoadingState, RootState } from '../../types'

// Selectors
export const selectors = {
  getLoadingState: (state: RootState) => state.loading,
}

// Slice
export const initialState: LoadingState = false

export const { actions, reducer } = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoadingState: (_, action: PayloadAction<LoadingState>) => action.payload,
  },
})
