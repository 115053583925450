import React from 'react'
import classnames from 'classnames'
import { Link, useLocation } from 'react-router-dom'

import hamburgerIcon from '../../images/hamburger.svg'
import { NavItems } from './NavItems'
import { Props as HeaderProps } from '.'
import { routes } from '../../routes'
import sendiumLogo from '../../images/sendium-logo.svg'
import './Header.scss'

export interface Props extends HeaderProps {
  isScrolled: boolean
  isBannerVisible: boolean
}

export const Header: React.FC<Props> = ({
  isBannerVisible,
  isScrolled,
  menuItems,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { pathname } = useLocation()
  const headerClass = classnames('Header', {
    'Header--withShadow': isScrolled && !isBannerVisible,
  })
  const mobileNavItemClasses = classnames('Header-mobileNavItems', {
    'Header-mobileNavItems--open': isOpen,
  })
  const body = document.querySelector('body')
  const toggleNav = React.useCallback(() => {
    if (body) {
      const overflow = isOpen ? 'auto' : 'hidden'

      body.style.overflow = overflow
    }

    setIsOpen(!isOpen)
  }, [body, isOpen])

  React.useEffect(() => {
    if (body) {
      body.style.overflow = 'auto'
    }

    setIsOpen(false)
  }, [body, pathname])

  return (
    <>
      <div className={headerClass}>
        <Link to={routes.home}>
          <img alt="Sendium" className="Header-logo" src={sendiumLogo} />
        </Link>

        <div className="Header-desktopNavItems">
          <NavItems menuItems={menuItems} />
        </div>

        <button
          aria-label="toggle menu"
          className="Header-button"
          onClick={toggleNav}
        >
          <img
            alt="toggle menu icon"
            className="Header-mobileMenuIcon"
            src={hamburgerIcon}
          />
        </button>
      </div>

      <div className={mobileNavItemClasses}>
        <NavItems menuItems={menuItems} />
      </div>
    </>
  )
}
