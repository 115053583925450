import React from 'react'
import classnames from 'classnames'

import { useSliderContext } from '../../hooks/useSliderContext'

interface Props {
  className?: string
  children?: React.ReactNode
}

export const Container: React.FC<Props> = ({ children, className }) => {
  const sliderContext = useSliderContext()

  return (
    <div
      className={classnames('Slider-container', className)}
      style={{
        backgroundImage: `url(${sliderContext.backgroundImage})`,
      }}
    >
      {children}
    </div>
  )
}
