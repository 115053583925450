import React from 'react'
import { useSelector } from 'react-redux'

import { MenuItem } from '../../types'
import { selectors as errorSelectors } from '../../redux/error'
import { useScrollContext } from '../../hooks/useScrollContext'

import { Header } from './Header'

export interface Props {
  menuItems: MenuItem[]
}

const WrappedHeader: React.FC<Omit<Props, 'isBannerVisible' | 'isScrolled'>> = (
  props,
) => {
  const { isScrolled } = useScrollContext()
  const isBannerVisible = Boolean(useSelector(errorSelectors.getError))

  return (
    <Header
      {...props}
      isBannerVisible={isBannerVisible}
      isScrolled={isScrolled}
    />
  )
}

export default WrappedHeader
