import { getURL } from '.'
import { I18nText, ValueProposition } from '../types'
import { Slide } from '../contexts/sliderContext'

export const buildValuePropSlides = (
  valueProps: ValueProposition[],
  i18nTextMap: { [k: string]: I18nText },
): Slide[] => {
  return valueProps.map((valueProp) => {
    return {
      backgroundImage: getURL(valueProp.background),
      content: i18nTextMap[valueProp.text_id]?.en,
      icon: getURL(valueProp.icon),
      title: i18nTextMap[valueProp.heading_text_id]?.en,
    }
  })
}
