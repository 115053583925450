import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AuthorizationState, RootState } from '../../types'

export const selectors = {
  getPhone: (state: RootState) => state.authorization.phone,
  getToken: (state: RootState) => state.authorization.token,
}

export const initialState: AuthorizationState = {
  phone: '',
  token: '',
}

export const { actions, reducer } = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
  },
})
