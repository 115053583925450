import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ErrorState, RootState } from '../../types'

// Selectors
export const selectors = {
  getError: (state: RootState) => state.error,
}

// Slice
export const initialState: ErrorState = ''

export const { actions, reducer } = createSlice({
  name: 'error',
  initialState,
  reducers: {
    clearError: () => '',
    setErrorMessage: (_, action: PayloadAction<string>) => action.payload,
  },
})
