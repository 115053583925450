const baseURL = import.meta.env.VITE_API_URL

export const endpoints = {
  addresses: `${baseURL}/address`,
  order: `${baseURL}/order`,
  phone: `${baseURL}/phone`,
  price: `${baseURL}/price`,
  product: `${baseURL}/product`,
  settings: `${baseURL}/setting`,
  tracking: `${baseURL}/track`,
  user: `${baseURL}/user`,
}
