import React from 'react'
import classnames from 'classnames'

import { useSliderContext } from '../../hooks/useSliderContext'

interface Props {
  className?: string
}

export const Icon: React.FC<Props> = ({ className }) => {
  const { icon, title } = useSliderContext()

  return (
    <img
      alt={title}
      className={classnames('Slider-icon', className)}
      src={icon}
    />
  )
}
