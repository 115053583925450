import React from 'react'

import * as constants from './constants'
import carbonNeutralIcon from '../../images/carbon-neutral.svg'
import digicertIcon from '../../images/digicert.png'
import facebookIcon from '../../images/facebook.svg'
import instagramIcon from '../../images/instagram.svg'
import './Footer.scss'

export interface Props {
  currentYear: number
  onClickPrivacyPolicy: () => void
  onClickTermsAndConditions: () => void
}

export const Footer: React.FC<Props> = ({
  currentYear,
  onClickPrivacyPolicy,
  onClickTermsAndConditions,
}) => (
  <div className="Footer">
    <div className="Footer-left">
      <a
        className="Footer-socialLink"
        href={constants.instagramURL}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img alt="Instagram" src={instagramIcon} />
      </a>

      <a
        className="Footer-socialLink"
        href={constants.facebookURL}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img alt="Facebook" src={facebookIcon} />
      </a>
    </div>

    <div className="Footer-middle">
      <div className="Footer-partnerIconWrapper">
        <img alt="Digicert" className="Footer-partnerIcon" src={digicertIcon} />
      </div>

      <div className="Footer-partnerIconWrapper">
        <img
          alt="Sendium Carbon-Neutral"
          className="Footer-partnerIcon"
          src={carbonNeutralIcon}
        />
      </div>
    </div>

    <div className="Footer-right">
      <div className="Footer-terms">
        <button className="Footer-button" onClick={onClickTermsAndConditions}>
          Terms and Conditions
        </button>
        {' | '}
        <button className="Footer-button" onClick={onClickPrivacyPolicy}>
          Privacy Policy
        </button>
      </div>

      <div className="Footer-copyright">
        <span>
          &#169; Copyright 2004 - {currentYear} Sendium. All rights reserved.
        </span>
      </div>
    </div>
  </div>
)
