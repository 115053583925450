import React from 'react'
import { useDispatch } from 'react-redux'

import { AppDispatch, ModalName } from '../../types'
import { Footer as UnwrappedFooter } from './Footer'
import { actions as modalActions } from '../../../src/redux/modal'
import './Footer.scss'

interface Props {
  date?: Date
}

export const Footer: React.FC<Props> = ({ date }) => {
  const dispatch = useDispatch<AppDispatch>()
  const today = date || new Date()
  const currentYear = today.getFullYear()

  const onClickTermsAndConditions = React.useCallback(
    () =>
      dispatch(modalActions.setOpenModalName(ModalName.TERMS_AND_CONDITIONS)),
    [dispatch],
  )

  const onClickPrivacyPolicy = React.useCallback(
    () => dispatch(modalActions.setOpenModalName(ModalName.PRIVACY_POLICY)),
    [dispatch],
  )

  return (
    <UnwrappedFooter
      currentYear={currentYear}
      onClickPrivacyPolicy={onClickPrivacyPolicy}
      onClickTermsAndConditions={onClickTermsAndConditions}
    />
  )
}
