import {
  GetCallTagPriceParam,
  GetLabelPriceParam,
  GetPickupPriceParam,
  Label,
  Pickup,
  ProductIdentifier,
} from '../types'
import { LabelModel, PickupModel } from '../models'

type Entity =
  | Label
  | GetLabelPriceParam
  | Pickup
  | GetPickupPriceParam
  | GetCallTagPriceParam

const fromLabel = (product_id: number, label: Label): ProductIdentifier => {
  if (!LabelModel.isComplete(label)) {
    throw new Error('Cannot compile identifier for incomplete Label')
  } else {
    const {
      delivery_location: {
        address: { address_id: delivery_id },
      },
      pickup_location: {
        address: { address_id: pickup_id },
      },
      dimensions: { height, length, width },
      weight,
    } = label

    return `${product_id}_${pickup_id}-${delivery_id}_${height}x${length}x${width}_${weight}`
  }
}

const fromGetLabelPriceParam = (
  product_id: number,
  labelPriceParam: GetLabelPriceParam,
): ProductIdentifier => {
  const {
    destination_address_id,
    dimensions: { height, length, width },
    pickup_address_id,
    weight,
  } = labelPriceParam

  return `${product_id}_${pickup_address_id}-${destination_address_id}_${height}x${length}x${width}_${weight}`
}

const fromPickup = (product_id: number, pickup: Pickup): ProductIdentifier => {
  if (!PickupModel.isComplete(pickup)) {
    throw new Error('Cannot compile identifier for incomplete Pickup')
  } else {
    const {
      location: {
        address: { address_id },
      },
      pickup_date,
    } = pickup

    return `${product_id}_${address_id}-${pickup_date}`
  }
}

const fromGetPickupPriceParam = (
  product_id: number,
  pickupPriceParam: GetPickupPriceParam,
): ProductIdentifier => {
  const { pickup_address_id, pickup_date } = pickupPriceParam

  return `${product_id}_${pickup_address_id}-${pickup_date}`
}

const isLabel = (entity: Entity): entity is Label =>
  (entity as Label).dimensions !== undefined &&
  (entity as Label).delivery_location !== undefined

const isLabelPriceParam = (entity: Entity): entity is GetLabelPriceParam =>
  (entity as GetLabelPriceParam).product_id !== undefined &&
  (entity as GetLabelPriceParam).dimensions !== undefined

const isPickup = (entity: Entity): entity is Pickup =>
  (entity as Pickup).location !== undefined &&
  (entity as Pickup).special_instructions !== undefined

const isPickupPriceParam = (entity: Entity): entity is GetPickupPriceParam =>
  (entity as GetPickupPriceParam).product_id !== undefined &&
  (entity as GetPickupPriceParam).pickup_address_id !== undefined

export const buildProductIdentifier = (
  product_id: number,
  entity: Entity,
): ProductIdentifier => {
  if (isLabel(entity)) {
    return fromLabel(product_id, entity)
  } else if (isLabelPriceParam(entity)) {
    return fromGetLabelPriceParam(product_id, entity)
  } else if (isPickup(entity)) {
    return fromPickup(product_id, entity)
  } else if (isPickupPriceParam(entity)) {
    return fromGetPickupPriceParam(product_id, entity)
  } else {
    throw new Error('Received unknown Entity type')
  }
}
