interface Section {
  title: string
  content: string
}

interface ModalContent {
  MODAL_TITLE: string
  SECTIONS: Section[]
}

const constants: ModalContent = {
  MODAL_TITLE: 'Privacy Policy',
  SECTIONS: [
    {
      title: '',
      content:
        'Sendium is a service of OnePak, Inc. (hereinafter “we”, or “our”).  We understand the importance of protecting the privacy of Sendium.com users. The information collected is used to market and improve the services we and our subsidiaries offer, to improve the Sendium.com web site content, and to contact you with updates to the web site or for other marketing purposes. Certain areas of Sendium.com require registration or a password for access. Information obtained from registered users of these areas may also be used for marketing purposes, and cookies may be used in those and other areas, as is described in this Policy. Information obtained in these areas may also be used in accordance with agreements governing access to and use of the specific areas. In addition, visitors to Sendium.com may choose to register for a Sendium.com Login user ID and password to simplify access to some interactive features on Sendium.com. Log file data and other information gathered from registered users of Sendium.com are used to improve the Sendium.com customer experience.',
    },
    {
      title:
        'What information does Sendium capture about visitors to its web site?',
      content:
        'The Sendium.com web server uses an extended log file format which captures: date and time of visit, referring address (location from which a visitor comes to Sendium.com), type of Internet browser, and visitor’s IP address. (Each computer that connects to the Internet is assigned a unique number, an IP address, for identification purposes). The log file does not capture a visitor’s email address.',
    },
    {
      title: 'Does Sendium ask for personal information?',
      content:
        'Several areas of Sendium.com ask for personal information. This list may be expanded without notice. In the event that it is expanded, additional information may be required. In these areas, your name, address, email address, billing information, and business profile are requested. This information is collected to help us further develop our services, to provide you access to valuable OnePak Internet-based information and services, and to bill you for those services if you select to use them.',
    },
    {
      title: 'How does Sendium use the information?',
      content:
        'The information collected through this site helps us identify the type of web site content our customers value most. We use this information to market and improve the web site and our services. We also use this information to send you email notifications about updates to the web site, and to contact you by other means for marketing and other purposes. Our policy is to not give, sell or otherwise distribute the information collected through this site to third parties outside of OnePak, Inc. and its subsidiaries (unless required by law); provided, however, in some cases we may use suppliers to assist us in collecting, using or otherwise processing for our benefit the information obtained through this site. Our practice is to require our suppliers to conduct such activities consistent with this policy and our requirements.',
    },
    {
      title:
        'What happens when I leave Sendium.com to visit a linked web site?',
      content:
        'Parts of Sendium.com may consist of products and services hosted by third-party businesses. When you leave Sendium.com to visit one of these sites, the only information transferred to the third-party is the fact that you came from Sendium.com (the referring address). This practice allows the third-party to monitor its own web site traffic, but does not provide them with any information about you. OnePak is not responsible or liable for the independent privacy policies of our third-party sites. You should consult the privacy policies at those sites to determine how your information may be used.',
    },
    {
      title: 'What is a cookie?',
      content:
        'A cookie is a series of data characters that, when programmed into a web site, is placed by the web server into the browser’s application folder on your computer. Once placed onto your machine, the cookie will allow the web site to “recognize” you as a unique individual.',
    },
    {
      title: 'Does Sendium use cookies?',
      content:
        'Cookies are used to achieve two goals. The first is to provide OnePak with the capability to personalize information for certain segments of its customer base. Secondly, in some instances, cookies are used to allow OnePak the opportunity to associate individual customers with their information profiles.',
    },
    {
      title: 'Can cookies be removed from my hard drive?',
      content:
        'Yes, cookies can be removed from your hard drive. Also, depending on what type of web browser and what browser version you are using, you may be able to change the properties on your cookie file so that cookies are not used or saved. Please check with your browser provider for more information on removing cookies.',
    },
    {
      title: 'Additional resources on information privacy',
      content: `The Electronic Privacy Information Center focuses public attention on emerging civil liberties issues relating to the National Information Infrastructure. EPIC Alert is the online newsletter of the Electronic Privacy Information Center in Washington, DC. The newsletter is bi-weekly and covers issues related to privacy and civil liberties in the information age.

OnePak reserves the right to amend the Sendium.com Privacy Policy at any time with or without notice. Please check back frequently in the event of changes.

Your use of Sendium.com constitutes your agreement to this Privacy Policy.`,
    },
  ],
}

export default constants
