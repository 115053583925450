import React from 'react'
import { useSelector } from 'react-redux'

import { selectors } from '../../redux/loading'
import { Loader } from './Loader'
import './Loader.scss'

const LoaderContainer: React.FC = () => {
  const isLoading = useSelector(selectors.getLoadingState)
  React.useEffect(() => {
    if (isLoading) {
      document.body.classList.add('no-scroll-loading')
    }

    return () => {
      document.body.classList.remove('no-scroll-loading')
    }
  }, [isLoading])

  return isLoading ? <Loader /> : null
}

export default LoaderContainer
