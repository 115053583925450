import { DateProductAvailability, Package } from '../types'

const migrations = {
  0: (state: any) => {
    return {
      ...state,
      order: {
        ...state.order,
        carrier: undefined,
      },
    }
  },
  1: (state: any) => {
    return {
      ...state,
      loading: false,
    }
  },
  2: (state: any) => {
    return {
      ...state,
      verifiedAddresses: [],
    }
  },
  3: (state: any) => {
    return {
      ...state,
      app: {
        ...state.app,
        orderAuthorizationToken: '',
      },
    }
  },
  4: (state: any) => {
    return {
      ...state,
      confirmedOrders: {},
    }
  },
  5: (state: any) => {
    const newState = { ...state }

    newState.order.logistics_partner = state.order.carrier
    delete newState.order.carrier

    delete newState.order.pickup.quantity

    return newState
  },
  6: (state: any) => {
    const newState = { ...state }

    delete newState.loading

    return newState
  },
  7: (state: any) => {
    const newState = {
      ...state,
      order: {
        ...state.order,
        selectedProductIDs: [],
      },
      settings: {
        ...state.settings,
        product: [],
      },
    }

    delete newState.order.logistics_partner

    return newState
  },
  8: (state: any) => {
    const newState = {
      ...state,
      order: {
        ...state.order,
        availablePickupProductIDs: [],
        pickupDateAvailability: [],
      },
    }

    delete newState.settings.date_list

    return newState
  },
  9: (state: any) => {
    return {
      ...state,
      order: {
        ...state.order,
        productPriceMap: {},
      },
    }
  },
  10: (state: any) => {
    const { point_of_pickup: oldPointOfPickup } = state.order.pickup
    let point_of_pickup

    if (oldPointOfPickup) {
      point_of_pickup = { id: oldPointOfPickup, value: oldPointOfPickup }
    }

    return {
      ...state,
      order: {
        ...state.order,
        pickup: {
          ...state.order.pickup,
          point_of_pickup,
        },
      },
    }
  },
  11: (state: any) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        agreement: [],
        text: {},
      },
    }
  },
  12: (state: any) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        marketing: [
          {
            heading_text_id: 'professional_heading',
            text_id: 'professional',
            icon: '/images/sendium/marketing_logos.png',
          },
          {
            heading_text_id: 'safe_heading',
            text_id: 'safe',
            icon: '/images/sendium/marketing_home.svg',
          },
          {
            heading_text_id: 'neutral_heading',
            text_id: 'neutral',
            icon: '/images/sendium/marketing_carbon_neutral.svg',
          },
        ],
      },
    }
  },
  13: (state: any) => {
    const newProductPriceMap = Object.keys(state.order.productPriceMap).reduce(
      (acc, cur) => {
        const price = state.order.productPriceMap[cur]
        acc[cur] = { discount_price: price, price }

        return acc
      },
      {} as {
        [k: string]: { discount_price: number; price: number }
      },
    )

    return {
      ...state,
      order: {
        ...state.order,
        promo_code: '',
        productPriceMap: newProductPriceMap,
      },
    }
  },
  14: (state: any) => {
    const newPickupDateAvailability: DateProductAvailability[] = state.order.pickupDateAvailability.map(
      (pickupDateAvailability: DateProductAvailability) => ({
        ...pickupDateAvailability,
        display_date: pickupDateAvailability.date,
      }),
    )

    return {
      ...state,
      order: {
        ...state.order,
        pickupDateAvailability: newPickupDateAvailability,
      },
    }
  },
  15: (state: any) => {
    const { orderAuthorizationToken } = state.app
    const { phone } = state.order.pickup.location.contact

    delete state.app.orderAuthorizationToken
    delete state.confirmedOrders

    return {
      ...state,
      authorization: {
        phone,
        token: orderAuthorizationToken,
      },
    }
  },
  16: (state: any) => {
    return {
      ...state,
      order: {
        ...state.order,
        packages: [],
      },
    }
  },
  17: (state: any) => {
    return {
      ...state,
      order: {
        ...state.order,
        packages: state.order.packages.map((pkg: Package) => ({
          ...pkg,
          labelOnly: false,
        })),
      },
    }
  },
  18: (state: any) => {
    return {
      ...state,
      order: {
        ...state.order,
        packages: state.order.packages.map((pkg: Package) => ({
          ...pkg,
          pickup: { ...pkg.pickup, tracking_number: '' },
        })),
      },
    }
  },
  19: (state: any) => {
    return {
      ...state,
      order: {
        ...state.order,
        packages: state.order.packages.map((pkg: Package) => ({
          ...pkg,
          tracking: {
            created_at: '',
            product_id: -1,
            tracking_number: '',
          },
        })),
      },
    }
  },
  20: (state: any) => {
    return {
      ...state,
      order: {
        ...state.order,
        packages: state.order.packages.map((pkg: Package) => ({
          ...pkg,
          label: { ...pkg.label, status: '' },
        })),
      },
    }
  },
  21: (state: any) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        value_proposition: [],
      },
    }
  },
  22: (state: any) => {
    return {
      ...state,
      order: {
        ...state.order,
        callTag: undefined,
      },
    }
  },
  23: (state: any) => {
    return {
      ...state,
      order: {
        ...state.order,
        packages: state.order.packages.map((pkg: Package) => ({
          ...pkg,
          callTag: {
            product_id: -1,
          },
        })),
      },
    }
  },
}

export default migrations
