import { SendiumAxiosError } from '../types'

export function isSendiumAxiosError(
  error: Error | SendiumAxiosError,
): error is SendiumAxiosError {
  const err = error as SendiumAxiosError
  return Boolean(
    err.isAxiosError &&
      err.response &&
      err.response.data &&
      err.response.data.error,
  )
}
