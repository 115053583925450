import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import { createMigrate, persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

import { authorizationTimeout } from './middleware/authorizationTimeout'
import { errorBanner } from './middleware/errorBanner'
import migrations from './migrations'
import { reducer as appReducer } from './app'
import { reducer as authorizationTimeoutReducer } from './authorizationTimeout'
import { reducer as authorizationReducer } from './authorization'
import { reducer as errorReducer } from './error'
import { reducer as loadingReducer } from './loading'
import { reducer as modalReducer } from './modal'
import { reducer as orderReducer } from './order'
import { reducer as serviceReducer } from './service'
import { reducer as settingsReducer } from './settings'
import { reducer as verifiedAddressesReducer } from './verified_addresses'
import { isProduction } from '../environments'

export const rootReducer = combineReducers({
  app: appReducer,
  authorizationTimeout: authorizationTimeoutReducer,
  authorization: authorizationReducer,
  error: errorReducer,
  loading: loadingReducer,
  modal: modalReducer,
  order: orderReducer,
  service: serviceReducer,
  settings: settingsReducer,
  verifiedAddresses: verifiedAddressesReducer,
})

const persistConfig = {
  blacklist: ['authorizationTimeout', 'error', 'loading', 'modal'],
  key: 'root',
  storage,
  version: 23,
  migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const middleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: ['persist/PERSIST'],
  },
}).concat(authorizationTimeout, errorBanner)

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: !isProduction(),
})

export const persistor = persistStore(store)
