import React from 'react'
import classnames from 'classnames'

import { useSliderContext } from '../../hooks/useSliderContext'

interface Props {
  className?: string
}

export const Title: React.FC<Props> = ({ className }) => {
  const { title } = useSliderContext()

  return <h3 className={classnames('Slider-title', className)}>{title}</h3>
}
