import React from 'react'

import { SliderContext } from '../../contexts/sliderContext'

export const useSliderContext = () => {
  const context = React.useContext(SliderContext)

  if (context === undefined) {
    throw new Error(
      'useSliderContext must be used within a SliderContextProvider',
    )
  }

  return context
}
