import React from 'react'

import { Footer } from '../../components/Footer'
import Header from '../../components/Header'
import { MenuItem, ParsedMarketingItem } from '../../types'
import { ValuePropSlider } from '../../components/ValuePropSlider'
import { MarketingTiles } from '../../components/MarketingTiles'

interface Props {
  marketingItems: ParsedMarketingItem[]
  menuItems: MenuItem[]
  children?: React.ReactNode
}

export const HomeLayout: React.FC<Props> = ({
  children,
  marketingItems,
  menuItems,
}) => {
  return (
    <div className="HomeLayout">
      <Header menuItems={menuItems} />

      <div className="HomeLayout-top">{children}</div>

      <div className="HomeLayout-bottom">
        <MarketingTiles marketingItems={marketingItems} />
      </div>

      <ValuePropSlider />

      <Footer />
    </div>
  )
}
