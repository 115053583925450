import React from 'react'

import { Footer } from '../../components/Footer'
import Header from '../../components/Header'
import { MarketingTiles } from '../../components/MarketingTiles'
import { MenuItem, ParsedMarketingItem } from '../../types'
import { ValuePropSlider } from '../../components/ValuePropSlider'

interface Props {
  displayBottomContent: boolean
  marketingItems: ParsedMarketingItem[]
  menuItems: MenuItem[]
  children?: React.ReactNode
}

export const PrimaryLayout: React.FC<Props> = ({
  children,
  displayBottomContent,
  marketingItems,
  menuItems,
}) => {
  return (
    <div className="PrimaryLayout">
      <Header menuItems={menuItems} />

      <div className="PrimaryLayout-top">{children}</div>

      {displayBottomContent && (
        <>
          <div className="PrimaryLayout-bottom">
            <MarketingTiles marketingItems={marketingItems} />
          </div>

          <ValuePropSlider />
        </>
      )}

      <Footer />
    </div>
  )
}
