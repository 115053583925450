import React from 'react'
import classnames from 'classnames'

import './Content.scss'

export interface Props {
  className?: string
  noPadding?: boolean
  children?: React.ReactNode
}

export const Content: React.FC<Props> = ({
  children,
  className,
  noPadding,
}) => {
  const contentClass = classnames('Modal-content', className, {
    'Modal-content--noPadding': noPadding,
  })

  return <div className={contentClass}>{children}</div>
}
