import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaymentMethod } from '@adyen/adyen-web/dist/types/types'

import { RootState } from '../../types'

export const selectors = {
  getJobID: (state: RootState) => state.app.jobID,
  getPaymentMethods: (state: RootState) => state.app.paymentMethods,
}

interface InitialState {
  jobID: string
  name: string
  paymentMethods: {
    paymentMethods: PaymentMethod[]
  }
}

// Slice
export const initialState: InitialState = {
  jobID: '',
  name: 'Sendium',
  paymentMethods: {
    paymentMethods: [],
  },
}
export const { actions, reducer } = createSlice({
  name: 'app',
  initialState,
  reducers: {
    reset: () => initialState,
    update: (state, action: PayloadAction<Partial<InitialState>>) => ({
      ...state,
      ...action.payload,
    }),
    updateJobID: (state, action: PayloadAction<string>) => ({
      ...state,
      jobID: action.payload,
    }),
  },
})
