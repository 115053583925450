import React from 'react'

import { getURL } from '../../utils'
import { ParsedMarketingItem } from '../../types'
import './MarketingTiles.scss'

interface Props {
  marketingItems: ParsedMarketingItem[]
}

export const MarketingTiles: React.FC<Props> = ({ marketingItems }) => {
  return (
    <>
      {marketingItems.map((marketingItem, index) => {
        return (
          <div className={`MarketingTile MarketingTile-${index}`} key={index}>
            <div className="MarketingTile-iconWrapper">
              <img
                alt={marketingItem.heading}
                className="MarketingTile-icon"
                src={getURL(marketingItem.icon)}
              />
            </div>

            <h3 className="MarketingTile-header">{marketingItem.heading}</h3>

            <p
              className="MarketingTile-text"
              dangerouslySetInnerHTML={{ __html: marketingItem.text }}
            />
          </div>
        )
      })}
    </>
  )
}
