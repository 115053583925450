import { buildProductIdentifier } from '../../utils'
import {
  GetLabelPriceParam,
  GetPickupPriceParam,
  GetPricesRequestParams,
  Order,
  ProductIdentifier,
  ServiceOption,
} from '../../types'
import { LabelModel, PickupModel } from '../../models'
import { PackageModel } from '../../models/package_model'

export const GetPricesAdapter = (
  order: Order,
  service: ServiceOption,
): GetPricesRequestParams => {
  const adaptedPickups = new Set<ProductIdentifier>()
  const { packages, promo_code } = order
  const products: Array<GetPickupPriceParam | GetLabelPriceParam> = []
  const completePackages = packages.filter((p) =>
    PackageModel.isComplete(p, service),
  )

  completePackages.forEach((pkg) => {
    const { label, labelOnly, pickup } = pkg

    if (
      !labelOnly &&
      PickupModel.isComplete(pickup) &&
      !adaptedPickups.has(buildProductIdentifier(pickup.product_id, pickup))
    ) {
      const adaptedPickupID = buildProductIdentifier(pickup.product_id, pickup)

      products.push({
        product_id: pickup.product_id,
        pickup_address_id: pickup.location.address.address_id || -1,
        quantity: PackageModel.pickupPackageQuantity(
          completePackages,
          adaptedPickupID,
        ),
        pickup_date: pickup.pickup_date || '',
      })

      adaptedPickups.add(adaptedPickupID)
    }

    if (LabelModel.isComplete(label)) {
      products.push({
        product_id: label.product_id,
        pickup_address_id: label.pickup_location.address.address_id || -1,
        destination_address_id:
          label.delivery_location.address.address_id || -1,
        dimensions: label.dimensions,
        quantity: 1,
        weight: label.weight,
      })
    }
  })

  return { action: 'get_prices', product: products, promo_code }
}
