import React from 'react'
import lazyWithPreload, { PreloadableComponent } from 'react-lazy-with-preload'
import { RouteProps } from 'react-router'

import { PageHeight } from './types'
import { routes } from './routes'

const lazy: typeof lazyWithPreload = (fn) =>
  lazyWithPreload(() =>
    fn().catch((error) => {
      if (error.name === 'ChunkLoadError') window.location.reload()
      throw error
    }),
  )

const AboutPage = lazy(() => import('./pages/AboutPage'))
const AgreementsPage = lazy(() => import('./pages/AgreementsPage'))
const CallTagPage = lazy(() => import('./pages/CallTagPage'))
const CallTagInstructionsPage = lazy(
  () => import('./pages/CallTagInstructionsPage'),
)
const CarbonNeutralPage = lazy(() => import('./pages/CarbonNeutralPage'))
const CreateLabelPage = lazy(() => import('./pages/labels/CreateLabelPage'))
const DeliveryAddressPage = lazy(
  () => import('./pages/labels/DeliveryAddressPage'),
)
const DimensionsPage = lazy(() => import('./pages/labels/DimensionsPage'))
const FunctionFlagsPage = lazy(() => import('./pages/FunctionFlagsPage'))
const HomePage = lazy(() => import('./pages/HomePage'))
const HowManyPackagesPage = lazy(() => import('./pages/HowManyPackagesPage'))
const LabelPickupAddressPage = lazy(
  () => import('./pages/labels/LabelPickupAddressPage'),
)
const LogisticsPartnerSelectionPage = lazy(
  () => import('./pages/packages/LogisticsPartnerSelectionPage'),
)
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'))
const OrderConfirmationPage = lazy(
  () => import('./pages/OrderConfirmationPage'),
)
const OrderHelpPage = lazy(() => import('./pages/OrderHelpPage'))
const OrderSummaryPage = lazy(() => import('./pages/OrderSummaryPage'))
const PackageLocationPage = lazy(
  () => import('./pages/packages/PackageLocationPage'),
)
const PackageNamePage = lazy(() => import('./pages/packages/PackageNamePage'))
const PackageSummaryPage = lazy(() => import('./pages/PackageSummaryPage'))
const PaymentPage = lazy(() => import('./pages/PaymentPage'))
const PickupAddressPage = lazy(
  () => import('./pages/packages/PickupAddressPage'),
)
const PickupDatePage = lazy(() => import('./pages/packages/PickupDatePage'))
const PrintLabelsPage = lazy(() => import('./pages/PrintLabelsPage'))
const ReviewTrackingInfoPage = lazy(
  () => import('./pages/packages/ReviewTrackingInfoPage'),
)
const SchedulePickupPage = lazy(() => import('./pages/SchedulePickupPage'))
const ServiceSelectionPage = lazy(() => import('./pages/ServiceSelectionPage'))
const SpecialInstructionsPage = lazy(
  () => import('./pages/packages/SpecialInstructionsPage'),
)
const TrackingNumberPage = lazy(
  () => import('./pages/packages/TrackingNumberPage'),
)
const WhatKindOfLabelPage = lazy(
  () => import('./pages/packages/WhatKindOfLabelPage'),
)

type RouteMapType = {
  [path: string]: {
    Component: PreloadableComponent<React.FC<object>>
    preloadPaths: string[]
    props: RouteProps & { pageHeight?: PageHeight }
  }
}
export const RouteMap: RouteMapType = {
  [routes.home]: {
    Component: HomePage,
    preloadPaths: [],
    props: {
      exact: true,
    },
  },
  [routes.howManyPackages]: {
    Component: HowManyPackagesPage,
    preloadPaths: [
      routes.packages.name(),
      routes.packages.newLabel(),
      routes.selectService,
    ],
    props: {
      exact: true,
    },
  },
  [routes.agreements]: {
    Component: AgreementsPage,
    preloadPaths: [routes.orderSummary, routes.payment],
    props: {
      exact: true,
      pageHeight: PageHeight.LARGE,
    },
  },
  [routes.callTag]: {
    Component: CallTagPage,
    preloadPaths: [routes.packageSummary, routes.orderSummary],
    props: {
      exact: true,
    },
  },
  [routes.callTagInstructions()]: {
    Component: CallTagInstructionsPage,
    preloadPaths: [routes.orderConfirmation(), routes.payment],
    props: {
      exact: true,
    },
  },
  [routes.packages.logisticsPartner()]: {
    Component: LogisticsPartnerSelectionPage,
    preloadPaths: [
      routes.packages.labelDimensions(),
      routes.packages.schedulePickup(),
    ],
    props: {
      exact: true,
    },
  },
  [routes.packages.pickupAddress()]: {
    Component: PickupAddressPage,
    preloadPaths: [
      routes.packages.pickupDate(),
      routes.packages.reviewTrackingInfo(),
    ],
    props: {
      exact: true,
    },
  },
  [routes.packages.packageLocation()]: {
    Component: PackageLocationPage,
    preloadPaths: [
      routes.packages.pickupDate(),
      routes.packages.specialInstructions(),
    ],
    props: {
      exact: true,
    },
  },
  [routes.selectService]: {
    Component: ServiceSelectionPage,
    preloadPaths: [routes.howManyPackages],
    props: {
      exact: true,
    },
  },
  [routes.packages.name()]: {
    Component: PackageNamePage,
    preloadPaths: [
      routes.howManyPackages,
      routes.packages.labelPickupAddress(),
      routes.packages.newLabel(),
      routes.packages.trackingNumber(),
      routes.packageSummary,
    ],
    props: {
      exact: true,
    },
  },
  [routes.packages.newLabel()]: {
    Component: CreateLabelPage,
    preloadPaths: [],
    props: {
      exact: true,
      pageHeight: PageHeight.LARGE,
    },
  },
  [routes.packages.labelDeliveryAddress()]: {
    Component: DeliveryAddressPage,
    preloadPaths: [
      routes.packages.labelDimensions(),
      routes.packages.labelPickupAddress(),
    ],
    props: {
      exact: true,
    },
  },
  [routes.packages.labelPickupAddress()]: {
    Component: LabelPickupAddressPage,
    preloadPaths: [
      routes.packages.labelDeliveryAddress(),
      routes.packages.name(),
    ],
    props: {
      exact: true,
    },
  },
  [routes.carbonNeutral]: {
    Component: CarbonNeutralPage,
    preloadPaths: [],
    props: {
      exact: true,
    },
  },
  [routes.packages.specialInstructions()]: {
    Component: SpecialInstructionsPage,
    preloadPaths: [routes.packages.packageLocation(), routes.packageSummary],
    props: {
      exact: true,
    },
  },
  [routes.packages.trackingNumber()]: {
    Component: TrackingNumberPage,
    preloadPaths: [
      routes.packages.name(),
      routes.packages.reviewTrackingInfo(),
      routes.packages.whatKindOfLabel(),
    ],
    props: {
      exact: true,
    },
  },
  [routes.packages.whatKindOfLabel()]: {
    Component: WhatKindOfLabelPage,
    preloadPaths: [
      routes.packages.reviewTrackingInfo(),
      routes.packages.trackingNumber(),
    ],
    props: {
      exact: true,
    },
  },
  [routes.packages.reviewTrackingInfo()]: {
    Component: ReviewTrackingInfoPage,
    preloadPaths: [
      routes.packages.pickupAddress(),
      routes.packages.trackingNumber(),
      routes.packages.whatKindOfLabel(),
    ],
    props: {
      exact: true,
    },
  },
  [routes.packages.pickupDate()]: {
    Component: PickupDatePage,
    preloadPaths: [
      routes.packages.packageLocation(),
      routes.packages.pickupAddress(),
      routes.packages.schedulePickup(),
    ],
    props: {
      exact: true,
    },
  },
  [routes.packageSummary]: {
    Component: PackageSummaryPage,
    preloadPaths: [routes.callTag, routes.orderSummary, routes.packages.name()],
    props: {
      exact: true,
    },
  },
  [routes.orderSummary]: {
    Component: OrderSummaryPage,
    preloadPaths: [
      routes.agreements,
      routes.packages.name(),
      routes.packageSummary,
    ],
    props: {
      exact: true,
      pageHeight: PageHeight.LARGE,
    },
  },
  [routes.payment]: {
    Component: PaymentPage,
    preloadPaths: [
      routes.agreements,
      routes.printLabels(),
      routes.callTagInstructions(),
    ],
    props: {
      exact: true,
      pageHeight: PageHeight.LARGE,
    },
  },
  [routes.packages.labelDimensions()]: {
    Component: DimensionsPage,
    preloadPaths: [
      routes.packages.labelDeliveryAddress(),
      routes.packages.logisticsPartner(),
    ],
    props: {
      exact: true,
      pageHeight: PageHeight.LARGE,
    },
  },
  [routes.packages.schedulePickup()]: {
    Component: SchedulePickupPage,
    preloadPaths: [
      routes.packages.logisticsPartner(),
      routes.packages.pickupDate(),
      routes.packageSummary,
    ],
    props: {
      exact: true,
    },
  },
  [routes.orderConfirmation()]: {
    Component: OrderConfirmationPage,
    preloadPaths: [],
    props: {
      exact: true,
    },
  },
  [routes.printLabels()]: {
    Component: PrintLabelsPage,
    preloadPaths: [routes.orderConfirmation(), routes.payment],
    props: {
      exact: true,
      pageHeight: PageHeight.LARGE,
    },
  },
  [routes.about]: {
    Component: AboutPage,
    preloadPaths: [],
    props: {},
  },
  [routes.orderHelp()]: {
    Component: OrderHelpPage,
    preloadPaths: [],
    props: {},
  },
  [routes.functionFlags]: {
    Component: FunctionFlagsPage,
    preloadPaths: [],
    props: {
      exact: true,
    },
  },
  [routes.any]: {
    Component: NotFoundPage,
    preloadPaths: [],
    props: {},
  },
}
