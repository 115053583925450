import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { actions, selectors } from '../../redux/error'

export const RouteTransition: React.FC = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const errorState = useSelector(selectors.getError)

  React.useEffect(() => {
    window.scrollTo(0, 0)

    if (!!errorState) {
      dispatch(actions.clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return null
}
