import { endpoints } from '../endpoints'
import {
  Address,
  GetAddressResponse,
  GetAvailabilityResponse,
} from '../../types'
import * as http from '../http'

type AddressParam = Omit<Address, 'residence' | 'address_id'>

export interface AddressesApi {
  getAvailability: (addressID: number) => GetAvailabilityResponse
  validate: (address: AddressParam) => GetAddressResponse
}

export const addresses: AddressesApi = {
  getAvailability: (addressID) =>
    http.unauthenticated.get(endpoints.product, {
      action: 'get_availability',
      pickup_address_id: addressID,
    }),
  validate: (address) =>
    http.unauthenticated.get(endpoints.addresses, {
      ...address,
      action: 'verify',
    }),
}
