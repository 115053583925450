import React from 'react'

import { ScrollContext } from '../../contexts/scrollContext'

export const useScrollContext = () => {
  const context = React.useContext(ScrollContext)

  if (context === undefined) {
    throw new Error(
      'useScrollContext must be used within a ScrollContextProvider',
    )
  }

  return context
}
