import { buildProductIdentifier } from '../../utils'
import { LabelModel } from '../label_model'
import {
  Package,
  Product,
  ProductIdentifier,
  ServiceOption,
  VerifyTrackingNumberResponse,
} from '../../types'
import { PickupModel } from '../pickup_model'

const completePartialTrackingDataMerge = (
  pkg: Package,
  pickupProduct: Product,
  trackingProduct: Product,
): Package => {
  return {
    ...pkg,
    pickup: {
      ...pkg.pickup,
      product_id: pickupProduct.product_id,
    },
    tracking: {
      ...pkg.tracking,
      product_id: trackingProduct.product_id,
    },
  }
}

const mergePartialTrackingData = (
  pkg: Package,
  partnerId: number,
  trackingNumber: string,
  apiData: VerifyTrackingNumberResponse,
): Package => {
  const { country: _pCountry, ...pickupLocation } =
    apiData.pickup_location || {}
  const { country: _dCountry, ...destinationLocation } =
    apiData.destination || {}
  const updatedPickupAddress = pickupLocation
    ? {
        ...pkg.pickup.location.address,
        ...pickupLocation,
      }
    : pkg.pickup.location.address

  const updatedLabelDeliveryLocationAddress = destinationLocation
    ? {
        ...pkg.label.delivery_location.address,
        ...destinationLocation,
      }
    : pkg.label.delivery_location.address

  return {
    ...pkg,
    label: {
      ...pkg.label,
      delivery_location: {
        ...pkg.label.delivery_location,
        address: {
          ...pkg.label.delivery_location.address,
          ...updatedLabelDeliveryLocationAddress,
        },
      },
    },
    pickup: {
      ...pkg.pickup,
      location: {
        ...pkg.pickup.location,
        address: updatedPickupAddress,
      },
      partner_id: partnerId,
    },
    tracking: {
      ...pkg.tracking,
      created_at: apiData.date_created || '',
      error: apiData.error,
      tracking_number: trackingNumber,
    },
  }
}

const mergeTrackingData = (
  pkg: Package,
  pickupProduct: Product,
  trackingProduct: Product,
  trackingNumber: string,
  apiData: VerifyTrackingNumberResponse,
): Package => {
  const { country: _pCountry, ...pickupLocation } =
    apiData.pickup_location || {}
  const { country: _dCountry, ...destinationLocation } =
    apiData.destination || {}
  const updatedPickupAddress = pickupLocation
    ? {
        ...pkg.pickup.location.address,
        ...pickupLocation,
      }
    : pkg.pickup.location.address

  const updatedLabelDeliveryLocationAddress = destinationLocation
    ? {
        ...pkg.label.delivery_location.address,
        ...destinationLocation,
      }
    : pkg.label.delivery_location.address

  return {
    ...pkg,
    label: {
      ...pkg.label,
      delivery_location: {
        ...pkg.label.delivery_location,
        address: {
          ...pkg.label.delivery_location.address,
          ...updatedLabelDeliveryLocationAddress,
        },
      },
    },
    pickup: {
      ...pkg.pickup,
      location: {
        ...pkg.pickup.location,
        address: updatedPickupAddress,
      },
      partner_id: pickupProduct.partner_id,
      product_id: pickupProduct.product_id,
    },
    tracking: {
      ...pkg.tracking,
      created_at: apiData.date_created || '',
      error: apiData.error,
      product_id: trackingProduct.product_id,
      tracking_number: trackingNumber,
    },
  }
}

const filteredByIdentifier = (
  packages: Package[],
  productIdentifier: ProductIdentifier,
): Package[] =>
  packages.filter(
    (pkg) =>
      !pkg.labelOnly &&
      PickupModel.isComplete(pkg.pickup) &&
      buildProductIdentifier(pkg.pickup.product_id, pkg.pickup) ===
        productIdentifier,
  )

const pickupPackageQuantity = (
  packages: Package[],
  productIdentifier: ProductIdentifier,
): number => filteredByIdentifier(packages, productIdentifier).length

const isComplete = (pkg: Package, service: ServiceOption): boolean => {
  const labelComplete =
    service === ServiceOption.PICKUP ||
    (LabelModel.isComplete(pkg.label) && pkg.label.product_id !== -1)
  const pickupComplete =
    (service === ServiceOption.BOTH && pkg.labelOnly) ||
    (PickupModel.isComplete(pkg.pickup) && pkg.pickup.product_id !== -1)

  return labelComplete && pickupComplete
}

const isTrackingInfoComplete = (pkg: Package): boolean => {
  return pkg.tracking.product_id >= 0 && Boolean(pkg.tracking.tracking_number)
}

const isCallTagInfoComplete = (pkg: Package): boolean => {
  return pkg.callTag.product_id >= 0
}

export const PackageModel = {
  completePartialTrackingDataMerge,
  filteredByIdentifier,
  isCallTagInfoComplete,
  isComplete,
  isTrackingInfoComplete,
  mergePartialTrackingData,
  mergeTrackingData,
  pickupPackageQuantity,
}
