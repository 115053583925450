import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AuthorizationTimeoutState, RootState } from '../../types'

// Selectors
export const selectors = {
  getAuthorizationTimeout: (state: RootState) => state.authorizationTimeout,
}

// Slice
export const initialState: AuthorizationTimeoutState = false

export const { actions, reducer } = createSlice({
  name: 'authorizationTimeout',
  initialState,
  reducers: {
    setAuthorizationTimeout: (
      _,
      action: PayloadAction<AuthorizationTimeoutState>,
    ) => action.payload,
  },
})
