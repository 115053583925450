import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ModalName, ModalState, RootState } from '../../types'

// Selectors
export const selectors = {
  getOpenModalName: (state: RootState) => state.modal.openModalName,
}

// Slice
export const initialState: ModalState = {
  openModalName: ModalName.NONE,
}

export const { actions, reducer } = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setOpenModalName: (state, action: PayloadAction<ModalName>) => ({
      ...state,
      openModalName: action.payload,
    }),
  },
})
