import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState, ServiceOption } from '../../types'

export const selectors = {
  getSelectedProduct: (state: RootState) => state.service.selectedService,
}

// Slice
export const initialState = { selectedService: ServiceOption.NONE }
export const { actions, reducer } = createSlice({
  name: 'service',
  initialState,
  reducers: {
    reset: () => initialState,
    updateServiceSelection: (state, action: PayloadAction<ServiceOption>) => ({
      ...state,
      selectedService: action.payload,
    }),
  },
})
