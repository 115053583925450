import React from 'react'

import loadingImage from '../../images/sendium-loader.gif'
import './Loader.scss'

const LOADING_MESSAGES = [
  'Pixel fairies are<br />hand-crafting your order',
  'Did you do something<br />different with your hair?',
  'Awkward Silence<br />is the worst...',
  "Its not you it's me",
  'Pixel fairies are<br />hand-crafting your order',
  'Did you do something<br />different with your hair?',
  'Awkward Silence<br />is the worst...',
  "Its not you it's me",
]

const SEVEN_SECONDS = 7000
const MESSAGE_WIDTH = 500
export const leftValue = (
  messageIndex: number,
  activeIndex: number,
): number => {
  const startingValue = messageIndex * MESSAGE_WIDTH
  const advancedValue = activeIndex * MESSAGE_WIDTH

  return startingValue - advancedValue
}

export const Loader: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(-1)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveIndex(activeIndex + 1)
    }, SEVEN_SECONDS)

    return () => clearTimeout(timeout)
  }, [activeIndex])

  return (
    <div className="Loader-overlay">
      <img alt="loading animation" src={loadingImage} />

      <div className="Loader-loadingMessageContainer">
        {LOADING_MESSAGES.map((message, idx) => (
          <p
            className="Loader-loadingMessage"
            dangerouslySetInnerHTML={{ __html: message }}
            key={idx}
            style={{ left: `${leftValue(idx, activeIndex)}px` }}
          />
        ))}
      </div>
    </div>
  )
}
