import React from 'react'
import {
  BrowserRouter,
  matchPath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  withRouter,
} from 'react-router-dom'
import classnames from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import {
  addTransitionToChild,
  handleLocationChanges,
} from './utils/addTransitionToChild'
import BasicLayout from './layouts/BasicLayout'
import HomeLayout from './layouts/HomeLayout'
import { Loader } from './components/Loader/Loader'
import { NavigationDirection } from './types'
import PreloadLazyComponents from './components/PreloadLazyComponents'
import PrimaryLayout from './layouts/PrimaryLayout'
import { RouteMap } from './RouteMap'
import { routes } from './routes'

const BASIC_LAYOUT_ROUTES = [
  routes.about,
  routes.carbonNeutral,
  routes.functionFlags,
  routes.orderConfirmation(),
  routes.orderHelp(),
]

const getLayout = (isBasicLayout: boolean, isHomePage: boolean) => {
  if (isBasicLayout) {
    return BasicLayout
  } else if (isHomePage) {
    return HomeLayout
  } else {
    return PrimaryLayout
  }
}

const AnimatedSwitch = withRouter(({ history, location }) => {
  const nextLocation = location
  const prevLocation = React.useRef(nextLocation)
  const locationHistory = React.useRef([nextLocation])
  const navDirection = React.useRef(NavigationDirection.FORWARD)
  const historyCount = React.useRef(history.length)
  const visited = React.useRef(false)

  handleLocationChanges(
    prevLocation,
    nextLocation,
    locationHistory,
    history.action,
    navDirection,
  )

  const homePageRouteMatch = useRouteMatch(routes.home) || { isExact: false }
  const isHomePage = homePageRouteMatch.isExact

  let isBasicLayout = false
  BASIC_LAYOUT_ROUTES.forEach((route) => {
    if (useRouteMatch(route)) {
      isBasicLayout = true
    }
  })

  const Layout = getLayout(isBasicLayout, isHomePage)
  const layoutChildClass = classnames({
    'PrimaryLayout-cardContainer': !isBasicLayout && !isHomePage,
  })

  let atManuallyEnteredURL = false
  const lastNavigation = window.performance.getEntriesByType(
    'navigation',
  )[0] as PerformanceNavigationTiming
  if (
    lastNavigation &&
    lastNavigation.type === 'navigate' &&
    new URL(lastNavigation.name).pathname === location.pathname
  ) {
    atManuallyEnteredURL = true
  } else if (
    performance.navigation.type === performance.navigation.TYPE_NAVIGATE &&
    historyCount.current === history.length
  ) {
    atManuallyEnteredURL = true
  }

  if (
    atManuallyEnteredURL &&
    ![...BASIC_LAYOUT_ROUTES, routes.home].some(
      (route) => matchPath(location.pathname, route)?.isExact,
    ) &&
    !visited.current
  ) {
    visited.current = true
    return <Redirect to={routes.packages.name(1)} />
  }

  return (
    <Layout>
      <TransitionGroup
        childFactory={(cssTransitionComponent) =>
          addTransitionToChild(
            cssTransitionComponent,
            prevLocation.current.pathname,
            nextLocation.pathname,
            navDirection.current,
          )
        }
        className={layoutChildClass}
        enter={!isBasicLayout}
        exit={!isBasicLayout}
      >
        <CSSTransition key={location.key} timeout={1000}>
          <Switch location={location}>
            {Object.entries(RouteMap).map(([path, route]) => (
              <Route {...route.props} key={path} path={path}>
                <route.Component />

                <PreloadLazyComponents preloadPaths={route.preloadPaths} />
              </Route>
            ))}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Layout>
  )
})

export const Router: React.FC = () => (
  <BrowserRouter>
    <React.Suspense fallback={<Loader />}>
      <AnimatedSwitch />
    </React.Suspense>
  </BrowserRouter>
)
