import React from 'react'
import { Link } from 'react-router-dom'

import { getURL } from '../../utils'
import { MenuItem } from '../../types'
import { routes } from '../../routes'

interface Props {
  menuItems: MenuItem[]
}

const ABOUT_MENU_ID = 57
const CONTACT_MENU_ID = 58
const CARBON_NEUTRAL_MENU_ID = 60

export const NavItems: React.FC<Props> = ({ menuItems }) => (
  <div className="Header-navItems">
    <ul>
      {menuItems.map((menuItem) => {
        if (menuItem.menu_id === CONTACT_MENU_ID) {
          return (
            <li key={menuItem.menu_id}>
              <a
                className="Header-navLink"
                href="mailto:support@sendium.com?subject=Sendium user query"
              >
                {menuItem.description}
              </a>
            </li>
          )
        }

        if (menuItem.menu_id === ABOUT_MENU_ID) {
          return (
            <li key={menuItem.menu_id}>
              <Link className="Header-navLink" to={routes.about}>
                {menuItem.description}
              </Link>
            </li>
          )
        }

        if (menuItem.menu_id === CARBON_NEUTRAL_MENU_ID) {
          return (
            <li key={menuItem.menu_id}>
              <Link
                className="Header-navLink Header-navLink--withIcon"
                style={{
                  backgroundImage: `url(${getURL(menuItem.icon)})`,
                  color: menuItem.color,
                }}
                to={routes.carbonNeutral}
              >
                {menuItem.description}
              </Link>
            </li>
          )
        }

        return (
          <li key={menuItem.menu_id}>
            <button className="Header-navLink">{menuItem.description}</button>
          </li>
        )
      })}
    </ul>
  </div>
)
