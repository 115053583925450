import { addresses, AddressesApi } from './addresses'
import { authorization, AuthorizationApi } from './authorization'
import { order, OrderApi } from './order'
import { phone, PhoneApi } from './phone'
import { PriceApi, prices } from './prices'
import { settings, SettingsApi } from './settings'
import { trackingNumber, TrackingNumberApi } from './trackingNumber'

export interface IAPI extends Object {
  [k: string]: { [k: string]: any }
  addresses: AddressesApi
  authorization: AuthorizationApi
  order: OrderApi
  phone: PhoneApi
  prices: PriceApi
  settings: SettingsApi
  trackingNumber: TrackingNumberApi
}

export const API: IAPI = {
  addresses,
  authorization,
  order,
  phone,
  prices,
  settings,
  trackingNumber,
}
