import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { actions as errorActions } from '../error'
import { API } from '../../api'
import {
  DateProductAvailability,
  GetPricesThunkPayload,
  Label,
  Order,
  Package,
  PackageType,
  Pickup,
  PointOfPickup,
  ProductPriceMap,
  RootState,
  SendiumErrorMessages,
  ServiceOption,
} from '../../types'
import { buildProductIdentifier, getMessageFromError } from '../../utils'
import { GetPricesAdapter } from '../../adapters/get_prices_adapter'

const GET_AVAILABILITY = 'order/get-availability'
export interface GetAvailabilityArg {
  addressID: number
  packageNumber: number
}
type GetAvailabilityThunkResponse = [number, DateProductAvailability[]]
export const getAvailability = createAsyncThunk<
  GetAvailabilityThunkResponse,
  GetAvailabilityArg
>(GET_AVAILABILITY, async ({ addressID, packageNumber }, { dispatch }) => {
  const { data } = await API.addresses.getAvailability(addressID)

  dispatch(
    actions.updatePackageAvailablePickupProductIDs({
      packageNumber,
      productIDs: data.availability.product_ids,
    }),
  )

  return [
    packageNumber,
    data.availability.dates,
  ] as GetAvailabilityThunkResponse
})

type GetPricesArg = {
  order: Order
  service: ServiceOption
}

const GET_PRICES = 'order/get-prices'
export const getPrices = createAsyncThunk<GetPricesThunkPayload, GetPricesArg>(
  GET_PRICES,
  async (args, baseThunkApi): Promise<GetPricesThunkPayload> => {
    const { order, service } = args
    const { dispatch } = baseThunkApi

    try {
      const params = GetPricesAdapter(order, service)

      const response = await API.prices.load(params)
      const productPriceMap: ProductPriceMap = {}

      response.data.product.forEach((productPrice, i) => {
        const initialProductParam = params.product[i]

        const id = buildProductIdentifier(
          productPrice.product_id,
          initialProductParam,
        )

        const { prices } = productPrice
        productPriceMap[id] = prices
      })

      return [true, productPriceMap]
    } catch (error) {
      const errorMessage = getMessageFromError(error as Error)

      if (errorMessage !== SendiumErrorMessages.INVALID_PROMO_CODE) {
        dispatch(errorActions.setErrorMessage(errorMessage))
      }

      return [false, errorMessage]
    }
  },
)

// Selectors
export const selectors = {
  getOrder: (state: RootState) => state.order,
  getPackages: (state: RootState) => state.order.packages,
  getPhoneNumber: (state: RootState) => {
    const { packages } = state.order
    const pkg = packages[0]

    if (pkg) {
      return (
        pkg.pickup.location.contact.phone ||
        pkg.label.pickup_location.contact.phone
      )
    }

    return ''
  },
  getPromoCode: (state: RootState) => state.order.promo_code,
}

const DEFAULT_LOCATION = {
  address: {
    address1: '',
    city: '',
    postal_code: '',
    state_province: '',
  },
  company: '',
  contact: {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  },
}

export const DEFAULT_LABEL: Label = {
  delivery_location: DEFAULT_LOCATION,
  dimensions: {
    height: 0,
    length: 0,
    width: 0,
  },
  name: '',
  package_type: PackageType.CUSTOM,
  partner_id: -1,
  pickup_location: DEFAULT_LOCATION,
  product_id: -1,
  status: '',
  weight: 0,
}

export const DEFAULT_PICKUP: Pickup = {
  location: DEFAULT_LOCATION,
  partner_id: -1,
  product_id: -1,
  pickup_date: undefined,
  point_of_pickup: undefined,
  special_instructions: '',
  tracking_number: '',
  status: '',
}

export const DEFAULT_PACKAGE: Package = {
  availablePickupProductIDs: [],
  callTag: {
    product_id: -1,
  },
  label: { ...DEFAULT_LABEL },
  labelOnly: false,
  name: '',
  packageNumber: -1,
  pickup: { ...DEFAULT_PICKUP },
  pickupDateAvailability: [],
  tracking: {
    created_at: '',
    product_id: -1,
    tracking_number: '',
  },
}

// Slice
export const initialState: Order = {
  packages: [],
  productPriceMap: {},
  promo_code: '',
}

export const { actions, reducer } = createSlice({
  name: 'order',
  initialState,
  reducers: {
    addPackage: (state: Order) => {
      const numPackages = state.packages.length
      const lastPackage = state.packages[numPackages - 1]
      const packageNumber = lastPackage ? lastPackage.packageNumber + 1 : 1

      return {
        ...state,
        packages: [...state.packages, { ...DEFAULT_PACKAGE, packageNumber }],
      }
    },
    removePackage: (state: Order, action: PayloadAction<Package>) => {
      const packages = [...state.packages]
      const packageIndex = state.packages.findIndex(
        ({ packageNumber }) => packageNumber === action.payload.packageNumber,
      )

      packages.splice(packageIndex, 1)

      return {
        ...state,
        packages,
      }
    },
    reset: () => initialState,
    setPackages: (state: Order, action: PayloadAction<Package[]>) => {
      state.packages = action.payload
    },
    updatePackage: (state: Order, action: PayloadAction<Package>) => {
      state.packages = state.packages.map((pkg) => {
        if (pkg.packageNumber === action.payload.packageNumber) {
          return action.payload
        }

        return pkg
      })
    },
    updatePackageLocation: (
      state: Order,
      action: PayloadAction<{
        pickup: Pickup
        pointOfPickup: PointOfPickup
      }>,
    ) => {
      const { pickup, pointOfPickup } = action.payload

      state.packages = state.packages.map((pkg) => {
        try {
          if (
            buildProductIdentifier(pkg.pickup.product_id, pkg.pickup) ===
            buildProductIdentifier(pickup.product_id, pickup)
          ) {
            return {
              ...pkg,
              pickup: {
                ...pkg.pickup,
                point_of_pickup: pointOfPickup,
              },
            }
          } else {
            return pkg
          }
        } catch {
          return pkg
        }
      })
    },
    updatePackageAvailablePickupProductIDs: (
      state: Order,
      action: PayloadAction<{ packageNumber: number; productIDs: number[] }>,
    ) => {
      state.packages = state.packages.map((pkg) => {
        if (pkg.packageNumber === action.payload.packageNumber) {
          return {
            ...pkg,
            availablePickupProductIDs: action.payload.productIDs,
          }
        } else {
          return pkg
        }
      })
    },
    updateSpecialInstructions: (
      state: Order,
      action: PayloadAction<{
        pickup: Pickup
        specialInstructions: string
      }>,
    ) => {
      const { pickup, specialInstructions } = action.payload

      state.packages = state.packages.map((pkg) => {
        try {
          if (
            buildProductIdentifier(pkg.pickup.product_id, pkg.pickup) ===
            buildProductIdentifier(pickup.product_id, pickup)
          ) {
            return {
              ...pkg,
              pickup: {
                ...pkg.pickup,
                special_instructions: specialInstructions,
              },
            }
          } else {
            return pkg
          }
        } catch {
          return pkg
        }
      })
    },
    updatePromoCode: (state: Order, action: PayloadAction<string>) => {
      state.promo_code = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailability.fulfilled, (state, action) => {
      const [packageNumber, pickupDateAvailability] = action.payload
      const packages = state.packages.map((pkg) => {
        if (pkg.packageNumber === packageNumber) {
          return { ...pkg, pickupDateAvailability }
        } else {
          return pkg
        }
      })

      return { ...state, packages }
    })

    builder.addCase(getPrices.fulfilled, (state, action) => {
      const result = action.payload
      if (result[0]) {
        state.productPriceMap = result[1]
      }
    })
  },
})
